<template>
  <v-btn
    icon
    v-bind="$attrs"
    v-on="$listeners"
    :class="cssClass"
    :ripple="false"
  >
    <v-icon :small="small">
      <slot></slot>
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "TemenoCircleButton",
  props: ["action", "small"],
  computed: {
    cssClass() {
      return this.action === "" || Boolean(this.action) ? "action" : "";
    },
    smallIcon() {
      return this.small === "" || Boolean(this.small);
    },
  },
};
</script>

<style scoped lang="scss">
.theme--dark {
  &.v-btn {
    background: url(../assets/button.svg);

    &.action {
      background: url(../assets/action-button.svg);

      &:hover {
        background: url(../assets/action-button-hover.svg);
      }
    }

    &::before {
      content: none;
    }

    &:hover {
      background: url(../assets/button-hover.svg);

      .v-btn__content {
        .v-icon {
          padding: 1px;
        }
      }
    }
  }
}
</style>
